export const kaceyConfig = {
    projectName: "forum-marketing",
    projectDescription: "Broadmind Forum Marketing API V1",
    version: "0.9",

    deploymentBucket: "broadmind-kacey-deployment-bucket",
    IAMrole: "arn:aws:iam::768325898747:role/broadmind-vinegret-sls-role",
    awsAccountId: "768325898747",
    domain: {
        base: "forumscanner.broadmind.eu",
        certificate: {
            hostedZoneId: "Z0217599ERGQMSPYXHAX",
            name: "broadmind.eu",
        }
    },
    firebase: {
        // FIREBASE_PROJ_ID: 'broadmind-chainstream',
        // FIREBASE_KEY: "AIzaSyDtS8MUx0cTaWhh3o0OH7KGL-xtv5ZlRKI",
        apiKey: "AIzaSyDtS8MUx0cTaWhh3o0OH7KGL-xtv5ZlRKI",
        authDomain: "broadmind-forumscanner.firebaseapp.com",
        projectId: "broadmind-forumscanner",
        storageBucket: "broadmind-forumscanner.appspot.com",
        messagingSenderId: "828330318427",
        appId: "1:828330318427:web:fb67a150410ecf800ffd7e",
        measurementId: "G-P6RPBFGEB4"
    },
    clarity: {
        id: "mffnwghndu"
    },
    googleAnalytics: {
        measurementId: "G-P6RPBFGEB4", //"G-9QNLG19Q58"
    }
}
