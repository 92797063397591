import { Image, Layout, Menu, Drawer, Row, Col, Button, ConfigProvider, theme, Progress } from 'antd';
import { KaceyView } from '../types';
import "../styles/app.css";
import { Link } from '../components/routing';
import { current } from '../lib/auth/actions';
import { websocketsEvents } from '../lib/websockets';
import { useRouter } from 'next/router';

const isLocalhost = /localhost/.test(win.location?.host || "");

const TawkMessengerReact = require('@tawk.to/tawk-messenger-react');

// import { activityStream } from '../lib/user/actions';

const { Header, Content } = Layout;
import keywords from "../data/keywords";

websocketsEvents.addListener("message", (m) => console.log(m));

const Separator = () => {
    const w = useIsWideScreen();
    return w ? <>&nbsp;|&nbsp;</> : <br />;
}

const ResponsiveLayout: KaceyView = (props) => {
    const [currUser, currUserProgress] = current();

    const menu = <Menu mode="horizontal" style={{ minWidth: 0, flex: "auto", justifyContent: "end" }}>
        {!currUser?.id ? (
            <Menu.Item key="si">
                <Link href="/auth">Sign In</Link>
            </Menu.Item>
        ) : (
            <>
                <Menu.SubMenu key={"dbsm"} title="Services">
                    <Menu.Item key="db">
                        <Link href="/console">Console</Link>
                    </Menu.Item>
                    <Menu.Item key="semaphore">
                        <Link href="/semaphore">Web Semaphores</Link>
                    </Menu.Item>
                    <Menu.Item key="keys">
                        <Link href="/semaphore/keys">API Keys</Link>
                    </Menu.Item>

                </Menu.SubMenu>
            </>
        )}


        <Menu.SubMenu title={<Link href="/docs/v1">Docs</Link>}>
            <TOCComponentTopLevel />
        </Menu.SubMenu>
        <Menu.Item key="blog">
            <Link href="/blog">Blog</Link>
            {/* <TOCComponentTopLevel /> */}
        </Menu.Item>
        <Menu.Item key="contact">
            <Link href="/contact">Contact Us</Link>
        </Menu.Item>
        <Menu.Item key="about">
            <Link href="/about">About</Link>
        </Menu.Item>

        {
            currUser?.id && (
                <Menu.Item style={{ alignSelf: "end" }} key="so">
                    <Link href="/signout">Sign Out</Link>
                </Menu.Item>
            )
        }
    </Menu >


    const r = useRouter();
    const isLanding = r.pathname == "/";
    const isSitemap = r.pathname == "/sitemap.xml";

    // const position = useScroll((document as any), (val) => val.top <= window.innerHeight * 1.2);

    const { pathname } = useRouter();

    const toUppercase = (word: string = "") => word[0].toUpperCase() + word.slice(1);

    const _slug = pathname.split(/\//).pop()?.split(/-/).filter(Boolean).map(word => toUppercase(word)).join(" ");
    const rootLayoutName = pathname.split(/\//)[1];

    // ugly ;s
    const slug = ["docs", "blog"].includes(rootLayoutName) && _slug == "V1" ? "" : _slug;

    const title = !rootLayoutName ?
        "WebSemaphore - serverless API concurrency management and traffic optimization in the cloud"
        :
        [["WebSemaphore", toUppercase(rootLayoutName)].filter(Boolean).join(" "), slug].filter(Boolean).join(" - ");

    const useSsr = useIsSsrPage();

    const hasHeader = rootLayoutName != "forum";

    return (<>
        <Head key="metakeywords">
            <title>{title}</title>
            <meta name="keywords" content={keywords}></meta>
            <meta name="description" content={`WebSemaphore is a cloud-native, serverless concurrency management solution. It provides seamless coordination and control
for API communication in modern, highly distributed cloud environments.`}></meta>
            <script type="text/javascript" src="//cdnjs.cloudflare.com/ajax/libs/dygraph/2.2.1/dygraph.min.js"></script>
            <link rel="stylesheet" type="text/css" href="//cdnjs.cloudflare.com/ajax/libs/dygraph/2.2.1/dygraph.min.css" />

        </Head>

        <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${kaceyConfig.googleAnalytics.measurementId}`} />
        {isLocalhost ? <></> : <Script
            id='google-analytics'
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${kaceyConfig.googleAnalytics.measurementId}', { 'linker': { 'domains': ['www.websemaphore.com', 'websemaphore.com'] } });

          // gtag('config', 'G-YMT320RGLJ', {
          //   page_path: window.location.pathname,
          // });
        `,
            }}
        />}

        <Layout>

            {hasHeader ? <Header>
                <Row justify="space-between">
                    <Col style={{ width: 130 }}>
                        <div style={{ marginTop: 0 }}>
                            <Link href="/">
                            <span style={{ color: "black", fontSize: "min(20px,max(3vw,14px))", margin: 0 }}>forum</span>
                            <span style={{ color: "#333333", fontSize: "min(20px,max(3vw,14px))", margin: 0 }}>s</span>
                            <span style={{ color: "#666666", fontSize: "min(20px,max(3vw,14px))", margin: 0 }}>canner</span>
                                {/* <div style={{ color: "black", fontSize: "8pt", lineHeight: 0, verticalAlign: "super", marginBottom: -15, paddingTop: 5 }}>Chainstream</div>
                                <span style={{ color: "black", fontSize: "min(20px,max(3vw,14px))", margin: 0 }}>WebSemaphore</span> */}
                            </Link>
                        </div>
                    </Col>
                    <Col style={{ textAlign: "right", width: "calc(100% - 130px)" }}>
                        <div style={{ textAlign: "right", display: "flex" }}>{menu}</div>
                    </Col>
                </Row>
            </Header> : <></>}
            <Content className="site-layout">
                <div className={"site-layout-background" + (isLanding ? "site-layout-background-landing" : "")}>
                    <div style={{ marginRight: "auto", marginLeft: "auto" }}>
                        {
                            (
                                !useSsr && !isLanding && !currUser?.id && currUserProgress.value.inProgress ?
                                    <IndefiniteProgress />
                                    :
                                    <div {...props} />
                            )
                        }
                    </div>
                </div>
            </Content>
            <div style={{ padding: "20px 10px" }} className={`global-footer ${isLanding ? "site-layout-background-landing" : ""} soft-vertical-background`}>
                &copy; 2019-{new Date().getFullYear()}<Separator />
                <Image
                    preview={false} placeholder={false} alt="Broadmind Cloud Technologies Logo" className="ant-image-img" src="/broadmind-logo-only.png" height="1em" style={{ height: "1em", marginTop: -3, padding: "0 5px 0 0" }}
                /><a href="https://www.broadmind.eu">Broadmind Cloud Technologies</a>
                <Separator />
                <Link href="/privacy">Privacy</Link><Separator />
                <Link href="/tos">Terms</Link><Separator />
                <Link href="/contact">Contact Us</Link>
            </div>
        </Layout >

    </>

    );
};


import { TOCComponentTopLevel } from "./toc";
import "./docs/v1/styles.css";

// import Script from 'next/script';
import Script from 'next/script';
import { IndefiniteProgress } from '../components/utils/progress';
import Head from 'next/head';
import { NoSsr, NoSsrOnMounted, useIsSsrPage } from '../lib/nextutils';
import { LayoutComponent } from '../components/masterLayouts/LayoutComponent';
import { Layout as DocsLayout } from "./docs/v1/layout";
import { Layout as BlogLayout } from "./blog/layout";
import { Layout as MarketingLayout } from "./intro/layout";

const layoutComps: Record<string, LayoutComponent> = {
    docs: DocsLayout,
    forum: ({ Component, pageProps, ...rest }) => <div {...rest}><Component {...pageProps} /></div>,
    blog: BlogLayout,
    intro: MarketingLayout,
    default: ({ Component, pageProps, ...rest }) => <div {...rest}><Component {...pageProps} /></div>
}

const docPages = ["about"];

const { default: tawk } = require("../components/marketing/tawk.to.html");

export const LayoutVariation: LayoutComponent = ({ Component, pageProps, rootLayoutName, ...rest }) => {
    const lc = docPages.includes(rootLayoutName || "") ? layoutComps.docs : layoutComps[rootLayoutName || "default"];
    const RootLayoutComp = lc || layoutComps.default;

    return <RootLayoutComp Component={Component} pageProps={pageProps} {...rest}>{<Component {...pageProps} />}</RootLayoutComp>
    {/* {isSsrPath ? "ssr" : "nossr"} {rootLayoutName} {useIsSsrPage() ? "ssr" : "nossr"} */ }

    // </>);
}

export const App: LayoutComponent = (props) => {
    const router = useRouter();

    const { pathname } = router;

    const rootLayoutName = pathname.split(/\//).filter(Boolean)[0];

    const useSsr = useIsSsrPage();
    const isSsrPath = !rootLayoutName || useSsr || (["blog", "docs"].includes(rootLayoutName));

    const maybeDynamic = (el: JSX.Element) => isSsrPath ? el : <NoSsrOnMounted noSsr={true}>{el}</NoSsrOnMounted>;

    const page = <ConfigProvider theme={{
        hashed: false,
        token: {
            algorithm: theme.darkAlgorithm,
            colorBgBase: '#fffefc',
            colorBgLayout: "#fffefc",
            colorPrimary: "darkorange",
            colorLink: "darkorange",
            colorLinkHover: "darkorange",
            borderRadius: 6,
            Header: {
                colorBgContainer: '#00b96b',
                colorBgLayout: 'green'
            },
            fontFamily: "Open Sans",
        } as any
    }}>
        {/* {isSsrPath ? "ssr" : "nossr"} */}
        {
            router.query["fullscreen"] ?

                <div style={{
                    marginLeft: "auto",
                    background: "#fff8f0",
                    marginRight: "auto",
                    padding: "150px 0 150px 0",
                    height: "calc(86svh + 2px)",
                    margin: "0 0 0 0",
                    overflow: "hidden",
                }}>
                    <div style={{ padding: "300px 30px 0 30px" }}>
                    <LayoutVariation {...{ ...props, rootLayoutName }} />
                    </div>
                </div>
                :

                <ResponsiveLayout>
                    <LayoutVariation {...{ ...props, rootLayoutName }} />
                </ResponsiveLayout>
        }
        <NoSsr>
            {isLocalhost ? <></> : <TawkMessengerReact propertyId="65a6a4878d261e1b5f53ff01" widgetId="1hk9f5d0g" />}
            {/* {
            (typeof window == "undefined") || window?.location.host.startsWith("localhost") ?
                <></> :
                <HTMLPlain text={chat3cx} />
        } */}
        </NoSsr>
        {/* <Script id="3cx" src="https://downloads-global.3cx.com/downloads/livechatandtalk/v1/callus.js"></Script> */}
        {isLocalhost ? <></> : <Script id="ms_clarity">
            {`
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${kaceyConfig.clarity.id}");
            `}
        </Script>
        }
    </ConfigProvider>;

    if(rootLayoutName == "forum")
        return <>{page}</>;

    return maybeDynamic(page);
}




import fs from "fs";
import matter from "gray-matter";
import { serialize } from "next-mdx-remote/serialize";
import { MDXRemote } from "next-mdx-remote";
import { useIsWideScreen } from '../hooks/useIsWideScreen';
import { win } from '../lib/utils';
import { kaceyConfig } from '../../forum-marketing-api/kacey.config';
// import { useAmp } from "next/amp";


export const getStaticProps = async ({ params }: any) => {
    const frontmatterFilename = `${params.slug}.mdx`;
    const frontmatterContent = fs.readFileSync(`pages/${frontmatterFilename}`, 'utf8');
    const { data, content } = matter(frontmatterContent);
    const mdxSource = await serialize(content);

    console.log({ frontmatterFilename, frontmatterContent })

    return {
        props: {
            frontmatter: data,
            mdxSource,
        },
    };
};

// const Dynamic = App;
export default App;


