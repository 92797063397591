import { current } from "../auth/actions";
import { token } from "../auth/token";
// import { WebSemaphoreHttpClientManager } from "websemaphore";
import { kcHttpApiClientManager } from "./api-client-manager";

// import {}

export const kcHttpClientManager = kcHttpApiClientManager();

export const kcApiClient = kcHttpClientManager.initialize({ baseUrl: "dev" });

if (typeof window != "undefined")
  setTimeout(() => {
    token.subscribe((val) => {
      kcHttpClientManager.updateToken(val?.jwt || "");
      // chainstreamClient.setSecurityData({ token: val?.jwt || "" });
      current();
    });
  })
