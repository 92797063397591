import { blogLayoutFactory } from "../../components/masterLayouts/blog";

export const tocData = [
    { 
        link: "/blog/introducing-websemaphore", 
        text: "Introducing WebSemaphore", 
        points: [{ title: "The 101 of understanding WebSemaphore." }]
    },
    { 
        link: "/blog/concurrency-in-systems-integration", 
        text: "Concurrency In Systems Integration", 
        points: [
            { title: "Concurrency and backpressure management are frequent concerns in systems integration" },
            { title: "Typical solutions involve clusters of complex systems to maintain redundancy" },
            { title: "Learn how WebSemaphore resolves multiple associated concenrs" }
        ]
    },
    {
        text: "On Integrations and Semaphores",
        link: "/blog/on-integrations-and-semaphores", 

        points: [
            { title: "The original use case that triggered the creation of WebSemaphore" },
            { title: "How a seemingly simple Salesforce to Oracle integration requirement prompts complex requirements" },
            { title: "Concurrency and optimal resource usage at scale" }
        ]
    },
    { 
        link: "/blog/handling-api-traffic-spikes", 
        text: "Handling API Traffic Spikes", 
        points: 
            [
                { title: "API traffic spikes put business continuity at risk" },
                { title: "Planning for maximum traffic is an expensive option" },
                { title: "Learn how WebSemaphore helps optimize traffic patterns and costs" }
            ]
    }
    // ,
    // { 
    //     link: "/blog/retry-exponential-backoff", 
    //     text: "Handling API Traffic Spikes", 
    //     points: 
    //         [
    //             { title: "Retry strategies are often applied where communication could fail" },
    //             { title: "Why this may not be an ideal solution" },
    //             { title: "WebSemaphore is an easy alternative" }
    //         ]
    // },
    // { link: "", text: "" }
]
  
export const Layout = blogLayoutFactory(tocData);

export default function Page() {};