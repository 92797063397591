import { Signal, signal } from "@preact/signals-react";
import { progressiveHandler } from "../ProgressiveHandler";
import { JWT_LOCALSTORAGE, token } from "./token";
import firebase from "./methods/firebase";
import { firebaseConfig } from "./config";
import { win } from "../utils";
import { ActionCodeURL, User } from "firebase/auth";
import { cache } from "../app";
import { kcApiClient, kcHttpClientManager } from "../clients/api-client";
import { UserReadResponse, IdSessionTokenRequest, OkResponse } from "websemaphore";

const _current: Signal<UserReadResponse> = signal({});

// firebase.initialize(firebaseConfig);

export const current = () =>
    progressiveHandler(
        // req/cache key
        undefined,

        // cache query
        _current.value, //Promise.resolve(current) as PromiseExtended,

        // how to fetch
        async (progress) => {
            if (typeof window == "undefined") //ssr
                return Promise.resolve(progress);

            // return progress;
            const res = await withFirebaseAuth(() => kcApiClient.user.current()); // withFirebaseAuth is only needed to avoid duplicate signups
            // // console.log(res.data);
            _current.value = res.data;

            return progress;
        },
        {
            autostart: true
        }
    );


export const signOut = async (params?: { route?: string | boolean, delay?: number }) => {
    return new Promise((resolve) => {
        setTimeout(async () => {
            localStorage.removeItem(JWT_LOCALSTORAGE)
            kcApiClient.setSecurityData({ token: "" });

            await cache.delete();
            await cache.open();

            await firebase.logout();


            fbUser.value = null;

            _current.value = {};

            if (params?.route !== false)
                win.location = params?.route || "/";

            resolve(undefined);
        }, params?.delay || 0);
    })
}

export const signInPassword = () =>
    progressiveHandler(
        // req/cache key
        undefined,

        // cache query
        token.value, //Promise.resolve(current) as PromiseExtended,

        // how to fetch
        async (progress, cache, params: IdSessionTokenRequest) => {
            //client.updateToken()
            const res = await kcApiClient.auth.getJwt(params)

            const jwt = res.data?.jwt || "";
            token.value = { jwt: jwt };

            if (!jwt)
                return { error: new Error("Bad credentials") };

            kcApiClient.setSecurityData({ token: jwt });

            localStorage.setItem(JWT_LOCALSTORAGE, jwt);

            const [_, pc] = current()
            pc.value.exec();

            return {
                ...progress,
                done: false
            };
        }
        // {
        //     autostart: true
        // }
    );




export const fbUser: Signal<User | null> = signal(null);
const auth = firebase.initialize(firebaseConfig)
auth.onAuthStateChanged((u) => {
    // console.log("Firebase auth: ", u);
    fbUser.value = u;
});


export const firebaseEmailSignin = () =>
    progressiveHandler(
        { key: "fbsigninaction" },
        fbUser,
        async (progress, cache, params) => {
            const urlParams = new URLSearchParams(win?.location?.search || "");
            const oobCode = urlParams.get("oobCode");

            const email = urlParams.get("email"); //win.localStorage?.getItem('emailForSignIn') || "";

            try {
                if (email && oobCode)
                    await firebase.signInWithEmailLink(email, win.location.href);
            } catch (ex) {
                throw new Error("That didn't work, please try again.")
            }
            win.localStorage?.setItem('emailForSignIn', "");

            await Promise.resolve();

            return progress
        },
        {
            autostart: false
        }
    )

export const firebaseEmailVerify = () =>
    progressiveHandler(
        { key: "fbrequestaction" },
        fbUser,
        async (progress, cache, { email, actionPath }: { email: string, actionPath: string }) => {
            firebase.requestEmailAuthCode({ email }, { actionPath });

            return progress
        },
        {
            autostart: false
        }
    )


export const withFirebaseAuth = async <T>(f: () => Promise<T>, withFirebase?: boolean) => {
    withFirebase = withFirebase ?? true;
    const token = withFirebase && fbUser.value && fbUser.value.getIdToken && (await fbUser.value.getIdToken(true));
    // chainstreamClient.
    const t = kcHttpClientManager.getCurrentToken();
    if (token) {
        kcHttpClientManager.updateToken(`Firebase ${token}`);

        kcApiClient.setSecurityData({ token: `Firebase ${token}` })
    }
    const res = await f();
    if (token)
        kcHttpClientManager.updateToken(t);

    return res;
}

const passwordUpdateSignal = new Signal<OkResponse>({ ok: false });

export const userUpdatePassword = (params?: { recovery?: boolean }) =>
    progressiveHandler(
        undefined,
        passwordUpdateSignal,
        async (progress, cache, { oldPass, newPass }: { oldPass: string, newPass: string }) => {
            // firebase.requestEmailAuthCode({ email }, { actionPath });

            const res = await withFirebaseAuth(
                () => kcApiClient.user.updatePassword({ old: oldPass, new: newPass }),
                !!params?.recovery
            );

            passwordUpdateSignal.value = res.data;

            return progress
        }
    );



// export const firebaseSignout = () =>
//     progressiveHandler(
//         undefined,
//         fbUser,
//         async (progress) => {


//             return progress
//         }
//     )